const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 订单取消
 */
export default function getList(val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/PurchaseOrder/updatePurchaseOrderCancel"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}