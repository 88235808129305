//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import getDomestic from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse";
import getInternational from "@/lib/data-service/default/web_common_area_getInternationalAreaChildListByParentIdResponse";

export default {
  data() {
    return {
      cityList: {
        type: [{label:'全部',value:''},{label:'国内',value:'100000'},{label:'国际',value:'0'}],
        area:[],
        province:[],
        city:[]
      },
      formdata: {
          cityType:'',
          area:'',
          province:'',
          city:''
      },

    };
  },
  props:{
      selectType:{
          type:String,
          default:''
      },
      defaultState:{
          type:Number,
          default:0
      },
      type:{
          type:String,
          default:''
      }
  },
  watch:{
      defaultState(value){
          let formdata = this.formdata
          let cityList = this.cityList
          formdata.cityType = ''
          formdata.area = '',formdata.province = '',formdata.city = '',
          cityList.area = [],cityList.province = [], cityList.city = []
          this.sendCity()
      }
  },
  created(){
      this.type=='tourWithGroup'||this.type=='independentTour'?this.cityList.type = [{label:'全部',value:''},{label:'国内',value:'100000'}]:''
  },
  methods: {
    _getCityList(id,key,type) {
        let formdata = this.formdata
        let cityList = this.cityList
        if(!id)return (formdata.area = '',formdata.province = '',formdata.city = '',
        cityList.area = [],cityList.province = [], cityList.city = [])
        type=='100000'?
        this._getDomestic(id,key):this._getInternational(id,key)
    },
    restora(key){
        key=='area'?(this.formdata.area = '',this.formdata.province = '',this.formdata.city = ''):
        key=='province'?(this.formdata.province = '',this.formdata.city = ''):
        key=='city'?this.formdata.city='':''
    },
    _getDomestic(id, key) {
      this.restora(key)
      getDomestic({ id: id }).then(res => {
        this.$set(this.cityList, key, res.areaTreeResult);
      });
    },
    _getInternational(id, key) {
      this.restora(key)
      getInternational({ id: id }).then(res => {
        this.$set(this.cityList, key, res.areaTreeResult);
      });
    },
    sendCity(){
        let data = Object.assign({selectType:this.selectType},this.formdata)
        this.$emit('selectedCity',data)
    }
  }
};
