
const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 查看原因
 */
export default function reservaOrder(val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/common/salesOrder/getAuditdetails"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}