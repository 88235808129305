const __request = require(`@/lib/data-service/default/__request/`)

/**
 * 跟团游列表
 * http://tour-beta.yinzhilv.com/doc.html#/yinzhilv_travel/purchase-controller/buyerOrderListUsingPOST_2
 */
export default function getList(val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/PurchaseOrder/buyerOrderList"
    pParameter.data = val
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}